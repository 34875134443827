<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center">
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mb-4 justify-content-end align-items-center"
                    >
                        <b-button
                            variant="primary"
                            size="md"
                            class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                            @click="addRegister"
                            v-b-popover.hover.top="'Presione alt+a o option+a'"
                            :disabled="
                                !permisos.includes('factura_proveedor-crear')
                            "
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 blue-active-text"
                            ></feather>
                            Nueva Compra
                        </b-button>
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes(
                                    'factura_proveedor-query-export'
                                ) &&
                                !permisos.includes(
                                    'factura_proveedor-query-export-and-email'
                                ) &&
                                !permisos.includes(
                                    'factura_proveedor-crud-export'
                                ) &&
                                !permisos.includes(
                                    'factura_proveedor-crud-import'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes(
                                        'factura_proveedor-query-export'
                                    )
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'factura_proveedor-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                            <!--
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button
                                    @click="getImportFormat(moduleName)"
                                    :disabled="
                                        !permisos.includes('factura_proveedor-crud-export')
                                    "
                                    >Plantilla para importación de
                                    tabla</b-dropdown-item-button
                                >
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button
                                    @click="$refs.excelInput.click()"
                                    :disabled="
                                        !permisos.includes('factura_proveedor-crud-import')
                                    "
                                    >Cargar Plantilla para importación de
                                    tabla</b-dropdown-item-button
                                > -->
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="mt-5">
                    <b-form v-hotkey="keymap">
                        <b-row class="mt-5">
                            <b-col cols="12" sm="4">
                                <b-form-group>
                                    <label for="fecha_inicio"
                                        >Fecha Inicio</label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha_inicio"
                                            v-model="
                                                $v.form.fecha_inicio.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState('fecha_inicio')
                                            "
                                            autocomplete="null"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.form.fecha_inicio.$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateState(
                                                        'fecha_inicio'
                                                    )
                                                "
                                                aria-controls="fecha_inicio"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="$v.form.fecha_inicio.formatDate"
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.form.fecha_inicio.compareDates
                                        "
                                    >
                                        La fecha inicial no puede ser mayor a la
                                        fecha final
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="fecha_inicio"
                                        >Fecha Final</label
                                    >
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha_final"
                                            v-model="$v.form.fecha_final.$model"
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="
                                                validateState('fecha_final')
                                            "
                                            autocomplete="null"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.form.fecha_final.$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="
                                                    validateState('fecha_final')
                                                "
                                                aria-controls="fecha_final"
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="$v.form.fecha_final.formatDate"
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.form.fecha_final.compareDates
                                        "
                                    >
                                        La fecha inicial no puede ser mayor a la
                                        fecha final
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="4">
                                <label for="prefijo">Prefijo</label>
                                <jautocomplete
                                    id="prefijo"
                                    :items="consecutivos"
                                    item-value="prefijo"
                                    item-text="prefijo"
                                    open-on-focus
                                    v-model="$v.form.prefijo_id.$model"
                                />
                                <!-- return-object
                                    @change="
                                        ($evt) =>
                                            ($v.form.prefijo_id.$model =
                                                $evt.prefijo)
                                    " -->
                                <!-- v-model="$v.form.prefijo_id.$model" -->
                                <!-- :state="validateState('prefijo_id')" -->
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="filter-input"
                                        >Texto adicional para buscar</label
                                    >
                                    <b-form-input
                                        id="filter-input"
                                        type="text"
                                        placeholder="Escriba algún texto para buscar"
                                        v-model="search"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="float-right mb-5">
                            <b-button
                                variant="primary"
                                size="md"
                                class="mt-4 d-flex align-items-center"
                                v-b-popover.hover.top="
                                    'Presione ctrl+b o cmd+b'
                                "
                                @click="getComprasMercancia()"
                            >
                                <feather
                                    type="search"
                                    size="1rem"
                                    class="text-blue-active mr-2"
                                ></feather>
                                <span>Consultar</span>
                            </b-button>
                        </div>
                    </b-form>
                </div>
                <div class="table-responsive mt-3">
                    <b-table
                        :fields="fields"
                        :items="comprasMercancia"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(prefijo)="data">
                            <span
                                :class="`badge bg-light-success ${Theme === 'dark' && 'text-gray'
                                    }`"
                            >
                                <feather
                                    type="hash"
                                    size="0.8rem"
                                    class="mr-1"
                                />
                                {{
                                    data.item.consecutivo.prefijo ||
                                    'Sin prefijo'
                                }}{{ data.item.numero }}
                            </span>
                        </template>
                        <template #cell(doc_electronico)>
                            <span>
                                NO
                            </span>
                        </template>
                        <template #cell(cliente_id)="data">
                            <span>
                                {{ data.item.proveedor.n_documento }} - {{ getLabelTercero(data.item.proveedor) }}
                            </span>
                        </template>
                        <template #cell(subtotal_neto)="data">
                            <span>
                                {{ currencyFormat(data.value.toFixed(2)) }}
                            </span>
                        </template>
                        <template #cell(total_descuento)="data">
                            <span>
                                {{ currencyFormat(data.value.toFixed(2)) }}
                            </span>
                        </template>
                        <template #cell(total_iva)="data">
                            <span>
                                {{ currencyFormat(data.value.toFixed(2)) }}
                            </span>
                        </template>
                        <template #cell(gran_total)="data">
                            <span>
                                {{ currencyFormat(data.value.toFixed(2)) }}
                            </span>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                type="eye"
                                role="button"
                                @click="
                                    $router.push({
                                        name: 'Ver compra mercancía',
                                        params: {
                                            compra_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Ver'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'factura_proveedor-export-individual-and-email'
                                    )
                                "
                                type="mail"
                                role="button"
                                class="ml-2"
                                v-b-popover.hover.top="'Enviar por correo'"
                                @click="sendSingleFactura(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="mail"
                                role="button"
                                class="ml-2 text-secondary"
                                v-b-popover.hover.top="'Enviar por correo'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'factura_proveedor-export-individual'
                                    )
                                "
                                type="printer"
                                role="button"
                                v-b-popover.hover.top="'Imprimir'"
                                class="ml-2"
                                @click="printCompra(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="printer"
                                role="button"
                                class="text-secondary ml-2"
                                v-b-popover.hover.top="'Imprimir'"
                            ></feather>
                            <feather
                                type="repeat"
                                role="button"
                                class="ml-2"
                                @click="
                                    $router.push({
                                        name: 'Crear devolucion proveedor',
                                        params: {
                                            compra_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Devolución en compra'"
                            ></feather>
                            <!-- <span
                                class="mdi mdi-file-pdf-box ml-2"
                                style="font-size: 1.6rem"
                            /> -->
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getComprasMercancia(1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="
                                getComprasMercancia($event, pagination.perPage)
                            "
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
            <selectFieldsReport
                :modulo="moduleName"
                :format="selectedFormat"
                :fields="
                    fieldsAvailables[
                        selectedFormat ? selectedFormat.toLowerCase() : 'excel'
                    ]
                "
                @close="selectedFormat = null"
            />
            <emailModal
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
            />
            <emailModal
                :id="'email-modal-single'"
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
                :url="'administracion/factura-proveedor/enviar-mail'"
                :alternativeOpc="'FIND_BY_ID'"
                :extraParamsDatajson="singleEmailParams"
            />
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 justify-content-between align-items-center"
            >
                <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('factura_proveedor-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nueva Compra
                </b-button>
                <b-dropdown
                    id="dropdown-divider"
                    text="Opciones"
                    size="md"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="
                        !permisos.includes('factura_proveedor-query-export') &&
                        !permisos.includes(
                            'factura_proveedor-query-export-and-email'
                        ) &&
                        !permisos.includes('factura_proveedor-crud-export') &&
                        !permisos.includes('factura_proveedor-crud-import')
                    "
                >
                    <template #button-content>
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <feather
                                type="more-horizontal"
                                size="1.5rem"
                                class="text-secondary"
                            ></feather>
                        </div>
                    </template>
                    <b-dropdown-item-button
                        v-for="(format, index) in formats"
                        :key="index"
                        @click="verifyFields(format.ext)"
                        :disabled="
                            !permisos.includes('factura_proveedor-query-export')
                        "
                    >
                        {{ format.name }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        @click="$bvModal.show('email-modal')"
                        :disabled="
                            !permisos.includes(
                                'factura_proveedor-query-export-and-email'
                            )
                        "
                        >Enviar informe por Correo</b-dropdown-item-button
                    >
                    <!--
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button
                                    @click="getImportFormat(moduleName)"
                                    :disabled="
                                        !permisos.includes('factura_proveedor-crud-export')
                                    "
                                    >Plantilla para importación de
                                    tabla</b-dropdown-item-button
                                >
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button
                                    @click="$refs.excelInput.click()"
                                    :disabled="
                                        !permisos.includes('factura_proveedor-crud-import')
                                    "
                                    >Cargar Plantilla para importación de
                                    tabla</b-dropdown-item-button
                                > -->
                </b-dropdown>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
import { addListenerCommands, removeListenerCommands, getLabelTercero, currencyFormat, firstToUpperString } from '@/utils/others'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import { compareDates, dateNow } from '@/utils/date'
// import * as XLSX from 'xlsx'
import Jautocomplete from '../../../../components/shared/autocomplete/jautocomplete.vue'
import print from 'print-js'

const formDefault = () =>
    JSON.parse(
        JSON.stringify({
            fecha_inicio: dateNow(-1),
            fecha_final: dateNow(),
            prefijo_id: null
        })
    )

const { API } = services

export default {
    name: 'MainComprasMercancia',
    components: { selectFieldsReport, emailModal, Jautocomplete },
    mixins: [validationMixin],
    mounted() {
        this.getLastQueryFilterStored()
        addListenerCommands(this)
        this.getEmpresaEmails()
        this.getAvailableFields()
        this.getConsecutivos()
        this.getComprasMercancia()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            { key: 'prefijo', label: 'Prefijo' },
            { key: 'doc_electronico', label: 'Doc. electrónico' },
            { key: 'fecha', label: 'Fecha' },
            { key: 'vencimiento', label: 'Vencimiento' },
            { key: 'cliente_id', label: 'Tercero' },
            { key: 'subtotal_neto', label: 'Subtotal' },
            { key: 'total_descuento', label: 'Descuento' },
            { key: 'total_iva', label: 'Iva' },
            { key: 'gran_total', label: 'Total' },
            { key: 'acciones', label: 'Acciones' }
        ],
        form: formDefault(),
        comprasMercancia: [],
        consecutivos: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        singleEmailParams: {},
        search: '',
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'factura_proveedor',
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ]
    }),
    validations: {
        form: {
            fecha_inicio: {
                compareDates: (d, a) => {
                    if (a.fecha_final) {
                        return compareDates(d, a.fecha_final)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            fecha_final: {
                compareDates: (d, a) => {
                    if (a.fecha_inicio) {
                        return compareDates(a.fecha_inicio, d)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            prefijo_id: {}
        }
    },
    methods: {
        showLog(data) {
            console.log(data)
        },
        firstToUpperString,
        getLabelTercero,
        currencyFormat,
        setQueryFilterNoStored(query) {
            const { fullPath } = this.$route

            this.setQueryFilter({ query: { ...query, search: this.search }, path: fullPath })
        },
        getLastQueryFilterStored() {
            // ir a consultar en el store si hay una query de busqueda para esta ruta
            const { fullPath } = this.$route
            const queryFilter = this.getQueryFilter(fullPath)

            // si hay una query se asigna a los valores por defecto
            // si no hay una query queda como está
            if (queryFilter) {
                const { search, path, ...rest } = queryFilter
                path

                this.form = rest
                this.search = search
            }
        },
        printCompra(factura_id) {
            API.POST({
                url: 'administracion/factura-proveedor/imprimir',
                data: {
                    p_datajson: {
                        id: factura_id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const base64 = dato.replace(
                            'data:application/pdf;base64,',
                            ''
                        )
                        print({
                            printable: base64,
                            type: 'pdf',
                            base64: true
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        sendSingleFactura(factura_id) {
            this.singleEmailParams = { id: factura_id }
            this.$bvModal.show('email-modal-single')
        },
        searchData() {
            this.getComprasMercancia(this.search)
        },
        showConfirm({ id, descripcion }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar la bodega "${descripcion}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteCategoria(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteCategoria(id) {
            API.POST({
                url: 'administracion/inventario/bodega/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getComprasMercancia()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getComprasMercancia(page = 1, limit = this.pagination.perPage) {
            const { fecha_inicio, fecha_final, prefijo_id } = this.form

            this.setQueryFilterNoStored(this.form)

            API.POST({
                url: 'administracion/factura-proveedor/query',
                data: {
                    p_datajson: {
                        page,
                        term: this.search,
                        limit,
                        fecha_inicio,
                        fecha_final,
                        prefijo: prefijo_id === 'Sin prefijo' ? '' : prefijo_id,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(content)
                        this.comprasMercancia = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        getAvailableFields() {
            if (this.permisos.includes('factura_proveedor-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getConsecutivos() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            clase_documento_id: 6,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL_BY_CLASE_DOCUMENTO',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.consecutivos = dato.map((c) => {
                                return c.prefijo === ''
                                    ? {
                                          ...c,
                                          prefijo: 'Sin prefijo'
                                      }
                                    : c
                            })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        addRegister() {
            this.$router.push(
                '/home/administrativo/documentos/compras-mercancia/crear-compra-mercancia'
            )
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        },
        ...mapActions(['getImportFormat']),
        ...mapMutations(['setQueryFilter'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'command+b': this.getComprasMercancia
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'ctrl+b': this.getComprasMercancia
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 45 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters(['getQueryFilter']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
